.swiper{
    padding: 42px;
    cursor: grab;
}
.swiper-slide{
    display: flex;
    justify-content: center;
}

/* .swiper-pagination-bullet-active{
    background-color: rgb(255, 196, 0);
} */

.swiper-button-prev{
    color: purple;
    cursor:grab;
}
.swiper-button-next{
    color: purple;
    cursor: grab;
}